@font-face {
	font-family: "HelvNeue";
	src: url("/static/font/HelvNeue/HelvNeue55_W1G.woff2") format("woff2"),
		url("/static/font/HelvNeue/HelvNeue55_W1G.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "HelvNeueBold";
	src: url("/static/font/HelvNeue/HelvNeue75_W1G.woff2") format("woff2"),
		url("/static/font/HelvNeue/HelvNeue75_W1G.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
