@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.footer {
	&__partner-title {
		display: none;
	}

	&__liens-commerciaux {
		padding: 20px 15px;

		@include gridle_state(md) {
			padding: 40px 0;
			max-width: 1024px;
			margin: 0 auto;
		}
	}
}
