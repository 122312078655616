@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.header-orange {
	position: relative; // to avoid transparent background on immersive sdp home page
	background: white; // avoid grey background of .smartdp
	z-index: 3;

	#o-header {
		height: auto !important; // avoid display a high white background before orange header
	}

	.o-service {
		&-title-domain a {
			font-size: revert;
			font-family: revert;
		}
	}
}
